<template>
  <div>
    <asset-header-card
      :asset="asset"
      :fftlog="true"
      :three-dimensional="false"
      :telemetry="false"
    />
    <b-card
      style="width: 100%; height: 70vh;"
      class="rounded p-0 flex-fill mb-0 mr-1 mt-0 text-center "
    >
      <div class="custom-search d-flex justify-content-end">
        <b-form-group class="m-1">
          <b-input-group size="lg">
            <b-form-input
              id="filterInput"
              v-model="tripID"
              type="search"
              placeholder="Input tripID to Search"
              :disabled="isBusy"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"
                size="lg"
                @click="searchByTripID"
              >
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <b-table
          ref="log-table"
          v-resize="setDimensions"
          :sticky-header="maxHeight"
          :no-border-collapse="noCollapse"
          responsive
          :items="items"
          :fields="fields"
          class="w-100"
          primary-key="tripID"
        >
          <template #cell(msEpoch)="row">
            {{ convertDate(row.item.msEpoch) }}
          </template>
          <template #row-details="row">
            <asset-fft
              :key="row.item.tripID"
              v-bind="row.item"
              :asset-i-d="row.item.assetID"
              :trip-i-d="row.item.tripID"
              :asset-name="row.item.assetName"
            />
          </template>
        </b-table>
        <b-overlay
          :show="isBusy"
          no-wrap
          opacity="0.5"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BOverlay, BCard, BButton, BFormInput, BInputGroup, BInputGroupAppend, BFormGroup,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import assetFactoryStore from '../assetsLogStore'
import AssetFft from './AssetFft.vue'
import AssetHeaderCard from '../components/AssetHeaderCard.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    AssetFft,
    BOverlay,
    AssetHeaderCard,
    BCard,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
  },
  props: {
    threeDimensional: {
      type: Boolean,
      default: false,
    },
    telemetry: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-asset-log'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetFactoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      maxHeight: '78vh',
      timer: null,
      stickyHeader: true,
      noCollapse: true,
      isBusy: false,
      tripID: 0,
      fields: [
        {
          key: 'deviceID', stickyColumn: true, isRowHeader: true, label: 'Device ID',
        },
        {
          key: 'tripID', stickyColumn: true, isRowHeader: true, label: 'Trip', sortable: true, primary: true,
        },
        {
          key: 'msEpoch', stickyColumn: true, isRowHeader: true, label: 'Time', sortable: true,
        },
      ],
      items: [],
      asset: {},
      after: 0,
    }
  },
  watch: {
    /* Optionally hide scrollbar when loading */
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs['log-table'].$el
        if (newVal === true) {
          tableScrollBody.classList.add('overflow-hidden')
        } else {
          tableScrollBody.classList.remove('overflow-hidden')
        }
      }
    },

    /* Optionally hide scrollbar when loading */
    tripID(newVal) {
      if (!newVal) {
        this.enableTimer()
      }
    },
  },
  created() {
    const d = new Date()
    let ms = d.getMilliseconds()
    ms -= (5 * 60 * 60 * 1000)
    this.after = ms
    this.fetchThisAssets()
    this.getItems()
    this.timer = window.setInterval(() => {
      if (!this.isBusy) {
        setTimeout(this.getItems(), 0)
      }
    }, 1000 * 10)
  },
  mounted() {
    const tableScrollBody = this.$refs['log-table'].$el
    /* Consider debouncing the event call */
    tableScrollBody.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    /* Clean up just to be sure */
    const tableScrollBody = this.$refs['log-table'].$el
    tableScrollBody.removeEventListener('scroll', this.onScroll)
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
      window.clearInterval(this.timer)
      clearTimeout(this.timer)
    }
  },
  methods: {
    onScroll(event) {
      if ((event.target.offsetHeight + event.target.scrollTop + 20) >= event.target.scrollHeight) {
        if (!this.isBusy) {
          this.loadMoretems()
        }
      }
    },
    fetchThisAssets() {
      store.dispatch('app-asset-log/fetchThisAssets', router.currentRoute.params.assetID)
        .then(response => {
          const { data } = response
          // eslint-disable-next-line prefer-destructuring
          this.asset = data[0]
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Asset Info',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getItems() {
      this.isBusy = false
      store
        .dispatch('app-asset-log/fetchAssetLogs', { assetID: router.currentRoute.params.assetID, limit: 50, after: this.after })
        .then(response => {
          // this.items = response.data
          if (this.items) {
            const diff = this.getDifference(response.data, this.items).reverse()
            diff.forEach(item => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              item._showDetails = true
              this.items.unshift(item)
            })
          } else {
            this.items = response.data
          }
          //  this.items = response.data
          this.after = this.items[0].msEpoch
          this.isBusy = false
          this.showOverlay = false
        })
        .catch(e => {
          console.log(e)
          this.isBusy = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        window.clearInterval(this.timer)
        clearTimeout(this.timer)
      }
    },
    enableTimer() {
      const d = new Date()
      let ms = d.getMilliseconds()
      ms -= (5 * 60 * 60 * 1000)
      this.after = ms
      this.getItems()
      this.timer = window.setInterval(() => {
        if (!this.isBusy) {
          setTimeout(this.getItems(), 0)
        }
      }, 1000 * 10)
    },
    searchByTripID() {
      // get last 13 digits of tripID
      const tripID13 = this.tripID.slice(-13)
      const fromTime = tripID13 - (1000 * 60 * 5)
      const toTime = tripID13 + (1000 * 60 * 5)
      this.isBusy = false
      this.clearTimer()
      store
        .dispatch('app-asset-log/fetchAssetLogs', { assetID: router.currentRoute.params.assetID, tripID: this.tripID, limit: 50 })
        .then(response => {
          this.items = response.data.map(item => {
            // eslint-disable-next-line no-underscore-dangle, no-param-reassign
            item._showDetails = true
            return item
          })
          this.isBusy = false
          this.showOverlay = false
        })
        .catch(e => {
          console.log(e)
          this.isBusy = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching fft list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadMoretems() {
      this.isBusy = false
      store
        .dispatch('app-asset-log/fetchAssetLogs', { assetID: router.currentRoute.params.assetID, limit: 50, before: this.items[this.items.length - 1].msEpoch })
        .then(response => {
          // this.items = response.data
          if (this.items) {
            const diff = this.getDifference(response.data, this.items)
            diff.forEach(item => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              item._showDetails = true
              this.items.push(item)
            })
          } else {
            this.items = response.data
          }
          //  this.items = response.data
          this.isBusy = false
          this.showOverlay = false
        })
        .catch(e => {
          console.log(e)
          this.isBusy = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getDifference(array1, array2) {
      return array1.filter(object1 => !array2.some(object2 => object1.tripID === object2.tripID))
    },
    convertDate(val) {
      return msEpochToDate(val)
    },
    setDimensions(data) {
      const { zoom } = data[0].target.style
      this.maxHeight = `${(78 * 100) / parseFloat(zoom)}vh`
    },
  },
}
</script>
