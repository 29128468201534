<template>
  <b-card>
    <app-echart-bar
      v-if="option"
      :option-data="option"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { integer } from '@/@core/utils/validations/validations'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    tripID: {
      type: integer,
      required: true,
    },
    assetID: {
      type: integer,
      required: true,
    },
  },
  data() {
    return {
      option: null,
    }
  },
  created() {
    this.fetchFFTData()
  },
  methods: {
    fetchFFTData() {
      const d = new Date()
      const currentyear = d.getFullYear()
      store.dispatch('app-asset-log/fetchFFTData', { path: `SG/${currentyear}/${this.assetID}/${this.tripID}.parquet` })
        .then(response => {
          const { data } = response
          this.option = {
            title: {
              left: 'left',
              top: 0,
            },
            grid: {
              left: '2%',
              top: '5%',
              right: '5%',
              bottom: '15%',
            },
            xAxis: [
              {
                scale: true,
                data: data.frequency,
                name: 'Frequency (Hz)',
              },
            ],
            yAxis: [
              {
                scale: true,
                name: `Magnitude (mg),  Samples:  ${data.fftDataPoints}`,
              },
            ],
            series: [
              {
                type: 'bar',
                symbolSize: 5,
                data: data.magnitude,
              },
            ],
          }
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching fft data ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
